const popups = document.querySelectorAll("[data-popup-wrapper]");

popups.forEach(function (popup) {
    const popup_backdrop = popup.querySelector("[data-popup-backdrop]");
    const popup_trigger = popup.querySelector("[data-popup-trigger]");
    const popup_target = popup.querySelector("[data-popup-target]");
    const popup_close = popup.querySelector("[data-popup-close]");
    const popup_link = popup.querySelector("a");
    popup_trigger.addEventListener(
        "click",
        function (event) {
            popup.classList.toggle("is-active");

            popup_trigger.classList.toggle("is-active");
            popup_target.classList.toggle("is-active");

            popup_trigger.setAttribute("aria-expanded", "true");
            popup_target.removeAttribute("aria-hidden");
            popup_close.removeAttribute("hidden");
            popup_link.removeAttribute("hidden");
        },
        false
    );
    function closePopup() {
        popup.classList.remove("is-active");

        popup_trigger.classList.remove("is-active");
        popup_target.classList.remove("is-active");

        popup_trigger.setAttribute("aria-expanded", "false");
        popup_target.setAttribute("aria-hidden", "true");
        popup_close.setAttribute("hidden", "true");
        popup_link.setAttribute("hidden", "true");
    }
    popup_close.addEventListener(
        "click",
        function (event) {
            closePopup();
        },
        false
    );
    popup_backdrop.addEventListener(
        "click",
        function (event) {
            closePopup();
        },
        false
    );
    document.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
            closePopup();
        }
    });
});
